.container {
    display: flex;
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.bg-marmeladenglaeser {
    background-image: url("../public/marmelade.webp");
    background-size: cover;
    background-position: 50% 0;
}

.bg-bergbau {
    background-image: url("../public/bergbau.webp");
    background-size: cover;
    background-position: 50% 0;
}

.left {
    display: flex;
    width: 40%;
    min-width: 475px;
    flex-direction: column;
}

.left-top {
    display: flex;
    align-items: center;
    justify-content: center;
    border: .25rem solid #fff;
    border-radius: 1rem;

    margin: .25rem 0 0 .25rem;
    
    height: 50%;
}

.left-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    border: .25rem solid #fff;
    border-radius: 1rem;

    margin: 0 0 .25rem .25rem;
    
    
    flex: 1 0 0;
    padding: 0 70px;
}

.left-bottom section {
    line-height: 1.4em;
    background: rgba(255,255,255, 0.5);
    backdrop-filter: blur(10px);
    padding: 13px 22px;
    border-radius: 1rem;

}

.right {
    flex: 1 0 0;
    background-image: url("../public/bg.webp");
    background-size: cover;
    background-position: 75% 0;
    border-radius: 1rem;
    border: .25rem solid #fff;
    margin: .25rem .25rem .25rem 0;
}

@media screen and (max-width: 900px) {
    .container {
        flex-direction: column;
    }
    .left {
        flex: 1 0 0;
        width: 100%;
    }
    .right {
        max-height: 20%;
        background-position: 50% 50%;
    }
    .left-top {
        max-height: 30%;
    }
    .right-top * {
        display: none;
    }
}

.right-top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    padding: 0 70px;
}

h1 {
    background: rgba(255,255,255, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    color: #0F2864;
    padding: 13px 22px;
    display: inline-block;
    white-space: nowrap;
    font-family: 'Lato', sans-serif;
}

h2 {
    color: #0F2864;
    font-size: 1.75em;
    text-align: center;
    padding-bottom: .25em;
    font-family: 'Lato', sans-serif;
}

#mapOfBochum {
    max-width: 700px;
}