#mapOfBochum {
    filter: drop-shadow(1px 5px 10px rgb(0 0 0 / .5));

    --location-color: 15 40 100;
    --map-tile-color: 255 255 255;
}

.stadtteil {
    fill: rgb(var(--map-tile-color)/.75);
    stroke: #919191;
    stroke-width: 1;
}

.location {
    fill: rgb(var(--location-color));
    cursor: pointer;
}

.location--beam {
    animation: grow 4s ease-in-out infinite;
    transform-origin: 220px 166px;
}

@keyframes grow {
    25% {
        fill: rgb(var(--location-color));
        transform: scale(0);
    }
    100% {
        fill: transparent;
        transform: scale(5);
    }
}