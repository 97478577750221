@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Roboto&display=swap');

*, ::after, ::before {
    box-sizing: border-box;
}

html, body {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    font-size: 1.05em;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --blue: 15 40 100;
}

#root {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.color-blue {
    color: rgb(var(--blue)/1)
}

.color-white {
    color: white;
}